import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"

import SectionedPageContent from '../components/sectionedPageContent/sectionedPageContent'
import CommoditiesContact from '../components/commoditiesContact/commoditiesContact'
  
export const query = graphql`
  query CommoditiesQuery {
    prismic {
      allCommoditiess {
        edges {
          node {
            title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_CommoditiesBodyCommodities_section {
                type
                label
                primary {
                  section_text
                  section_title
                  short_section_title
                  we_supply
                  background_image
                }
              }
            }
            introduction_text
            background_video {
              _linkType
              ... on PRISMIC__ImageLink {
                _linkType
                url
              }
              ... on PRISMIC__FileLink {
                _linkType
                url
              }
            }
          }
        }
      }
    }
  }
`

const Commodities = ({ data }) => {
  const doc = data.prismic.allCommoditiess.edges.slice(0,1).pop();
  if (!doc) return null;

  return (
    <React.Fragment>
      <SEO title="Commodities" />
      <SectionedPageContent doc={doc} />
      <CommoditiesContact />
    </React.Fragment>
  )
}

export default Commodities
