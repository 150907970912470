import React from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"

import ArrowLink from '../arrowLink/arrowLink'
 
const CommoditiesContact = ( props ) => (
  <div className="section bg-blue-highlight t-color-white">
    <div className="cols-wrapper">
      <div className="cols">
        <div className={`col-6-md col-6-lg`}>
          <h3 className={`t-h2 section-title t-color-white`}>Talk to us for further information regarding our available Commodities</h3>
          <div className={`t-body text-body`}>
            <p>If you have any further questions regarding Concords availaible  comodities services and how we can partner to support your needs.r</p>
          </div>
          <div className="button-block">
            <ArrowLink
              to="/contact"
              color="white"
              button="outline-white"
            >
              Contact us
            </ArrowLink>
          </div>
        </div>
      </div>
    </div>
  </div>
)

CommoditiesContact.propTypes = {
  title: PropTypes.array
}

CommoditiesContact.defaultProps = {
  title: []
}
 
export default CommoditiesContact;